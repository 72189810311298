import { campaigns, credit, ecom, ecom_config } from "./ecom";
import { tests, tests_config } from "./tests";

export default [
  {
    title: "lbl_home",
    route: "home",
    icon: "HomeIcon",
    action: "common",
  },
  ...tests,
  {
    title: "lbl_return_result",
    route: "processor_result",
    icon: "FilePlusIcon",
    action: "processor-service-result",
  },
  {
    title: "lbl_return_result",
    route: "supplier_result",
    icon: "FilePlusIcon",
    action: "service-result",
  },
  ...ecom,
  ...credit,
  ...campaigns,
  {
    title: "lbl_reports",
    route: "reports",
    icon: "BarChart2Icon",
    action: "report",
  },
  {
    title: "lbl_config_settings",
    route: "configs",
    icon: "SettingsIcon",
    children: [
      ...tests_config,
      ...ecom_config,
      {
        title: "config_roles.lbl_config_roles",
        route: "config-roles",
        action: "config-roles",
      },
    ],
    action: "configs",
  },
];

export const PARTNER_PRODUCT_MENU = [
  {
    title: "lbl_home",
    route: "home",
    icon: "HomeIcon",
    action: "common",
  },
  ...tests,
  {
    title: "lbl_return_result",
    route: "supplier_result",
    icon: "FilePlusIcon",
    action: "service-result",
  },
  ...ecom,
  ...credit,
  ...campaigns,
  {
    title: "lbl_campaigns",
    route: "campaigns",
    icon: "PercentIcon",
    action: "campaigns",
  },
  {
    title: "lbl_reports",
    route: "reports",
    icon: "BarChart2Icon",
    action: "report",
  },
  {
    title: "lbl_config_settings",
    route: "configs",
    icon: "SettingsIcon",
    children: [...tests_config, ...ecom_config],
    action: "configs",
  },
];
